import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert
} from '@mui/material';
import NavigationBarLogin from './NavigationBarLogin';
import { useNavigate } from 'react-router-dom';

function RegisterForm({ onLoginSuccess }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [registrationCode, setRegistrationCode] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [messageSeverity, setMessageSeverity] = useState('error'); // Possible values: 'error', 'success'
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!fullName) {
            setFeedbackMessage("Please specify your Name!");
            return;
        }

        if (!email) {
            setFeedbackMessage("Please enter a valid Email");
            return;
        }
        
        if (password !== confirmPassword) {
            setFeedbackMessage("Passwords don't match!");
            return;
        }

        if (!agreeTerms) {
            setFeedbackMessage("You must agree to the terms and conditions.");
            return;
        }

        // Password validation
        const minLength = 10;
        const hasNumber = /\d/; // Regular expression to check for at least one number
        const hasSpecialChar = /[!@#$%^&*(),;.:?"{}|<>°'+-~_´´=µ²³€]/; // Adjust the special characters as needed

        if (password.length < minLength || !hasNumber.test(password) || !hasSpecialChar.test(password)) {
            setFeedbackMessage("Password must be at least 10 characters long, contain at least one number, and one special character.");
            return;
        }

        try {
            const response = await axios.post('/api/register', { email, password, fullName, registrationCode });
            if(response.data.success) {
                setFeedbackMessage(response.data.message);
                setMessageSeverity('success');
                setTimeout(() => {
                    navigate('/login');
                }, 3000);

            } else {
                setFeedbackMessage(response.data.error);
                setMessageSeverity('error');
            }
        } catch (error) {
            const backendErrorMessage = error.response?.data?.error || "Registration failed. Please try again.";
            setFeedbackMessage(backendErrorMessage);
            setMessageSeverity('error');
        } 
    };



    return (
        <>
            <NavigationBarLogin/>
            <Container component="main" maxWidth="xs">
                <Box 
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Register
                    </Typography>
                    {feedbackMessage && (
                        <Alert severity={messageSeverity}  sx={{ width: '100%', mt: 2 }}>
                            {feedbackMessage}
                        </Alert>
                    )}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="fullName"
                            label="Full Name"
                            name="fullName"
                            autoComplete="name"
                            autoFocus
                            value={fullName}
                            onChange={e => setFullName(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="registrationCode"
                            label="Registration Code"
                            id="registrationCode"
                            type="password"
                            autoFocus
                            value={registrationCode}
                            onChange={e => setRegistrationCode(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox value={agreeTerms} onChange={e => setAgreeTerms(e.target.checked)} color="primary" />}
                            label="I agree to the terms and conditions."
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Register
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default RegisterForm;

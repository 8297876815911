import React, { createContext, useState, useEffect, useContext } from 'react';
import { styled, createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MainListItems, SecondaryListItems } from './dashboard/listItems';
import { BrowserRouter, Routes, Route, Router, Navigate, useNavigate, useLocation } from 'react-router-dom';
import LandingPage from './landing_page/LandingPage';
import Privacy from './landing_page/components/Privacy';
import Imprint from './landing_page/components/Imprint';
import ToS from './landing_page/components/ToS';
import Dashboard from './dashboard/Dashboard';
import Insights from './dashboard/Insights';
import Reports from './dashboard/Reports';
import Chat from './dashboard/Chat';
import Upload from './dashboard/Upload';
import Upload2 from './dashboard/Upload2';
import LoginForm from './dashboard/LoginForm';
import RegisterForm from './dashboard/RegisterForm';
import ProtectedRoute from './dashboard/ProtectedRoute';
import NavigationBarDashboard from './dashboard/NavigationBarDashboard';
import Drawer from './dashboard/Drawer';
import Configuration from './dashboard/Configuration';
import Confirmation from './dashboard/Confirmation';

import { AuthProvider, AuthContext } from './dashboard/AuthContext';
import { SubmitProvider, SubmitContext } from './dashboard/SubmitContext';

import { DateRangeContext } from './dashboard/DateRangeContext';


import './App.css'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        ᴉnsights
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      // Use a neutral, desaturated color for primary.
      main: '#333', // Dark grey, softer than black.
    },
    secondary: {
      // Choose a softer color for the secondary palette.
      main: '#a2a2a7', // Light grey that complements the primary color.
    },
    background: {
      default: '#fafafa', // A light background for contrast with the primary color.
    },
    text: {
      primary: '#333', // Dark text for readability.
    }
  },
  typography: {
    // Simplify the typography for a clean look.
    fontFamily: '"Helvetica Neue", Arial, sans-serif',
    fontSize: 12,
    button: {
      textTransform: 'none', // Non-capitalized button text.
    },
    logo: {
      fontFamily: '"Helvetica Neue", Arial, sans-serif',
      text: {
        primary: "red"
      },
      fontSize: 50
    }
  },
  spacing: 7,
  shape: {
    borderRadius: 8, // Soften edges of components.
  },
  components: {
    // Name of the component
    MuiList: {
      styleOverrides: {
        root: {
          overflowY: 'auto',
          "&::-webkit-scrollbar": {
            width: 7,
            borderRadius: "3px"
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "lightgrey",
            outline: `1px solid transparent`,
            borderRadius: "3px"
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "darkgrey",
            outline: `1px solid transparent`,
            borderRadius: "3px",
          }
        }
      }
    },
    MuiGrid2: {
      styleOverrides: {
        root: {
          overflowY: 'auto',
          "&::-webkit-scrollbar": {
            width: 7,
            borderRadius: "3px"
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "lightgrey",
            outline: `1px solid transparent`,
            borderRadius: "3px"
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "darkgrey",
            outline: `1px solid transparent`,
            borderRadius: "3px",
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        // Name of the slot
        root: ({ ownerState }) => ({
          // Some CSS
          ...(ownerState.active && {
            backgroundColor: 'lightgrey'
          }),
          "&:hover": {
            backgroundColor: "darkgrey",
          },
          borderRadius: '10px',
          margin: '5px',
          paddingLeft: '14px'
        })
      },
      variants: [
        {
          props: { variant: "logout" },
          style: {
            backgroundColor: '#f28f8f',
          },
        }
      ]
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "options" },
          style: {
            backgroundColor: 'orange',
            text: {
              color: "white"
            }
          }
        }
      ]
    }
  },
};

const defaultTheme = createTheme(themeOptions);

export default function App() {

  const [open, setOpen] = React.useState(true);
  const { isLoggedIn } = useContext(AuthContext);

  var t = new Date();
  var d = new Date();
  d.setDate(d.getDate() - 365);

  const [dateRange, setDateRange] = useState([d, t]);
  const [selectedColumns, setselectedColumns] = useState([]);
  const [selectedFilters, setselectedFilters] = useState([]);

  const updateFilter = (column, filter) => {
    setselectedColumns(column);
    setselectedFilters(filter);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  
  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <DateRangeContext.Provider value={{ dateRange, setDateRange, selectedColumns, selectedFilters, updateFilter }}>
          <SubmitProvider>
            <Box sx={{ 
              display: 'flex', 
              backgroundColor: (theme) => theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
              height: '98vh'
              }}>
              {//isLoggedIn && <NavigationBarDashboard open={open} handleDrawerToggle={toggleDrawer} />}
              }
              {isLoggedIn && (window.location.pathname != "/") && (
                <Drawer open={open} handleDrawerClose={toggleDrawer}/>
              )}

              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                }}
              >
                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }} overflow={'inherit'}>
                  <Grid container spacing={3}>
                    <Routes>
                      <Route path="/" element={<LandingPage />} />
                      <Route path="/privacy" element={<Privacy />} />
                      <Route path="/tos" element={<ToS />} />
                      <Route path="/imprint" element={<Imprint />} />
                      <Route path="/confirm/:userId" element={<Confirmation />} />
                      <Route path="/login" element={!isLoggedIn ? <LoginForm /> : <Navigate to="/dashboard" />} />
                      <Route path="/register" element={<RegisterForm /> }/>
                      <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} />
                      <Route path="/reports" element={isLoggedIn ? <Reports  /> : <Navigate to="/login" />} />
                      {/* <Route path="/chat" element={isLoggedIn ? <Chat /> : <Navigate to="/login" />} /> */}
                      <Route path="/insights" element={isLoggedIn ? <Insights /> : <Navigate to="/login" />} />
                      <Route path="/configuration" element={isLoggedIn ? <Configuration /> : <Navigate to="/login" />} />
                      <Route path="/upload" element={isLoggedIn ? <Upload2 /> : <Navigate to="/login" />} />
                    </Routes>
                  </Grid>
                  <Copyright sx={{ pt: 4 }} />
                </Container>
              </Box>
            </Box>
            </SubmitProvider>
          </DateRangeContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

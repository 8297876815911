import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {
  Button, Typography, Box, FormControl, TextField,
  CircularProgress, Dialog, DialogActions, Paper, List, ListItem
} from '@mui/material';
import axios from 'axios';

function PreviewComment(props) {
  return (
    <Paper sx={{ padding: "10px", width: "100%", borderRadius: "4px" }}>
      <Box>
        <Typography sx={{ fontSize: 12, mt: 0.5 }} color="text.secondary" gutterBottom>
          {props.date}, {props.location}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            mb: 0.5
          }}
        >
          {props.comment}
        </Typography>
      </Box>
    </Paper>
  );
}

function MapsPreview(props) {
  const [previewItems, setPreviewItems] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (props.query !== "") {
      setError(false);
      props.setLoading(true);
      axios.post('/api/dev/upload/previewGoogleReviews', {
        info: props.query,
      })
        .then(response => {
          setPreviewItems(response.data.slice(0, 5));
          props.setLoading(false);
        })
        .catch(error => {
          console.error('Error loading previews:', error);
          setPreviewItems([]);
          setError(true);
          props.setLoading(false);
        });
    }
  }, [props.query]);

  return (
    props.loading
      ? <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress mt={3} mb={3} />
        </div>
      : error
        ? <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: 16, m: 3 }} color="text.secondary" >
              No results
            </Typography>
          </div>
        : <List fullWidth>
            {previewItems.map((x, index) => (
              <ListItem key={index} sx={{ paddingLeft: "2px", paddingRight: "2px", paddingTop: "4px", paddingBottom: "4px" }}>
                <PreviewComment comment={x.Comment} location={x.Country} date={x.Date} score={x.NPS} maxScore={10} />
              </ListItem>
            ))}
          </List>
  );
}

export function MapsPopup(props) {
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const handlePreview = () => {
    setPreviewOpen(true);
  };

  const handleCancelPreview = () => {
    setPreviewOpen(false);
    props.onClose(); 
  };

  const handleAcceptPreview = () => {
    const info = `${companyName} ${address} ${streetNumber} ${postCode} ${city}`;
    fetchGoogleReviews(companyName, info);
    props.onClose(); 
  };

  const fetchGoogleReviews = (newIntegrationName, searchString) => {
    console.log("Started fetching reviews");

    if (isUploading) {
      console.log("Already uploading");
      return;
    }
    setIsUploading(true);

    setUploadStatus(prevStatus => ({ ...prevStatus, [newIntegrationName]: 'uploading' }));

    if (uploadedFiles.map(file => file.name).includes(newIntegrationName)) {
      setErrorMessage("Error: This file has already been uploaded.");
      console.log("Error, already integrated");
      setIsUploading(false);
      return;
    }

    axios.post('/api/dev/upload/googleReviews', {
      filename: newIntegrationName,
      info: searchString
    })
      .then((response) => {
        setUploadedFiles(prevFiles => [...prevFiles, { name: newIntegrationName, token: response.data.file_token }]);
        startStatusPolling(newIntegrationName, response.data.file_token);
        props.triggerSubmit();
        setIsUploading(false);
        setPreviewOpen(false); // Close the preview popup
        props.onClose(); // Close the main popup after upload
      })
      .catch(error => {
        if (error.response) {
          setErrorMessage(`Error: ${error.response?.data?.error || "An error occurred during the file upload."}`);
          setIsUploading(false);
        }
      });
  };

  const startStatusPolling = (newIntegrationName, fileToken) => {
    // Implement the logic for status polling here
  };

  return (
    <Dialog fullScreen={false} onClose={props.onClose} open={props.open} fullWidth maxWidth="sm">
      <Grid container p={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Enter the details of the company
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Please provide the company name, address, street number, post code, and city.
          </Typography>
        </Grid>
        
        <Grid item xs={12} mt={2} mb={2}>
          <Grid container justifyContent="center" width={"100%"}>
            <FormControl sx={{ minWidth: "100%" }}>
              <TextField
                label="Company Name"
                variant="outlined"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} mb={2}>
          <Grid container justifyContent="center" width={"100%"}>
            <FormControl sx={{ minWidth: "100%" }}>
              <TextField
                label="Address"
                variant="outlined"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={6} mt={2} mb={2}>
          <Grid container justifyContent="center" width={"100%"}>
            <FormControl sx={{ minWidth: "100%" }}>
              <TextField
                label="Street Number"
                variant="outlined"
                value={streetNumber}
                onChange={(e) => setStreetNumber(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={6} mt={2} mb={2}>
          <Grid container justifyContent="center" width={"100%"}>
            <FormControl sx={{ minWidth: "100%" }}>
              <TextField
                label="Post Code"
                variant="outlined"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} mb={2}>
          <Grid container justifyContent="center" width={"100%"}>
            <FormControl sx={{ minWidth: "100%" }}>
              <TextField
                label="City"
                variant="outlined"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={"center"} minWidth={"100%"}>
            <DialogActions sx={{ width: "100%" }}>
              <Grid container mt={3} width={"100%"}>
                <Grid item xs={6} pr={1}>
                  <Button
                    onClick={() => {
                      setCompanyName("");
                      setAddress("");
                      setStreetNumber("");
                      setPostCode("");
                      setCity("");
                      props.onCancel();
                    }}
                    variant="outlined"
                    color="primary"
                    sx={{ width: "100%" }}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs={6} pl={1}>
                  <Button
                    onClick={handlePreview}
                    variant="contained"
                    color="primary"
                    sx={{ width: "100%" }}
                  >
                    Preview
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Grid>

        <Dialog fullScreen={false} open={previewOpen} fullWidth maxWidth="sm" onClose={handleCancelPreview}>
          <Grid container p={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Preview</Typography>
            </Grid>
            <Grid item xs={12}>
              <MapsPreview query={`${companyName} ${address} ${streetNumber} ${postCode} ${city}`} loading={loading} setLoading={setLoading} />
            </Grid>
            <Grid item xs={12}>
              <DialogActions width="100%">
                <Grid container mt={3}>
                  <Grid item xs={6} pr={1}>
                    <Button
                      onClick={() => {
                        handleCancelPreview();
                      }}
                      variant="outlined"
                      color="primary"
                      sx={{ width: "100%" }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} pl={1}>
                    <Button
                      onClick={handleAcceptPreview}
                      variant="contained"
                      color="primary"
                      sx={{ width: "100%" }}
                    >
                      Accept and Upload
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    </Dialog>
  );
}

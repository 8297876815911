import React, { useContext, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, TextField, Button, Typography, CircularProgress, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DateRangeContext } from './DateRangeContext';
import { SubmitContext } from './SubmitContext';
import Filter from './Filter';

export function Options(props) {
    const theme = useTheme();
    const { dateRange, setDateRange } = useContext(DateRangeContext);
    const [quickRange, setQuickRange] = useState(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const [creditInfo, setCreditInfo] = useState({ credit_score: 0, max_credit_score: 0 });
    const [loading, setLoading] = useState(true);
    const { isSubmitted } = useContext(SubmitContext);

    // Fetch credit information
    useEffect(() => {
        const fetchCreditInfo = async () => {
            try {
                const response = await fetch('/api/dev/credit', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                setCreditInfo({ credit_score: data.credit_score, max_credit_score: data.max_credit_score });
            } catch (error) {
                console.error("Failed to fetch credit info:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCreditInfo();
    }, [isSubmitted]);

    function handleChange(value) {
        setQuickRange(value);
        const t = new Date();
        let d = new Date();
        switch (value) {
            case "Last Week":
                d.setDate(d.getDate() - 7);
                break;
            case "Last Month":
                d.setDate(d.getDate() - 30);
                break;
            case "Last 3 Months":
                d.setDate(d.getDate() - 92);
                break;
            case "Last 6 Months":
                d.setDate(d.getDate() - 183);
                break;
            case "Last Year":
                d.setDate(d.getDate() - 365);
                break;
            default:
                return;
        }
        setDateRange([d, t]);
    }

    const handleFilterOpen = () => {
        setFilterOpen(true);
    };

    const handleFilterClose = () => {
        setFilterOpen(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'right',
                    justifyContent: 'flex-end',
                    position: 'sticky',
                    gap: 2,
                    p: 1,
                    width: '100%',
                    top: '100px',
                    right: '110px',
                }}
            >
                {/* Date Pickers and Range Selector */}
                <DesktopDatePicker
                    label="From"
                    value={moment(dateRange[0])}
                    slotProps={{
                        textField: {
                            sx: {
                                width: '150px',
                                border: 'none',
                                color: 'black',
                            }
                        }
                    }}
                    maxDate={moment(dateRange[1])}
                    onChange={(newValue) => { setDateRange([newValue.startOf('day').toDate(), dateRange[1]]) }}
                />

                <DesktopDatePicker
                    label="To"
                    value={moment(dateRange[1])}
                    slotProps={{
                        textField: {
                            sx: {
                                width: '150px',
                                color: 'black',
                            }
                        }
                    }}
                    minDate={moment(dateRange[0])}
                    onChange={(newValue) => { setDateRange([dateRange[0], newValue.startOf('day').toDate()]) }}
                />

                <Autocomplete
                    value={quickRange}
                    options={["Last Week", "Last Month", "Last 3 Months", "Last 6 Months", "Last Year"]}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select range"
                        sx={{
                            width: '150px',
                        }}
                        InputLabelProps={{
                            sx: {
                                color: 'black', 
                            }
                        }}
                    />
                    )}
                    onChange={(event, newValue) => {
                        handleChange(newValue);
                    }}
                />

                <Button
                    variant="outlined"
                    onClick={handleFilterOpen}
                    sx={{
                        minWidth: '150px',
                        height: '52px',
                        fontSize: 14,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderColor: 'darkgrey',
                        color: 'black',
                        textTransform: 'none',
                    }}
                >
                    Filter
                    <FilterListIcon />
                </Button>

                {/* Vertical Line Separator */}
                <Box sx={{ borderLeft: `1px solid ${theme.palette.divider}`, height: '50px', mx: 2 }} />

                {/* Credit Info */}
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: 14,
                        border: "1px solid darkgrey",  
                        borderRadius: '8px',       
                        padding: '13px',         
                        display: 'inline-block'     
                    }}
                    variant="body1"
                >
                    {`${creditInfo.credit_score} ₹ / ${creditInfo.max_credit_score} ₹`}
                </Typography>
                )}
            </Box>

            {/* Dialog for Filter Options */}
            <Dialog open={filterOpen} onClose={handleFilterClose} maxWidth="lg" fullWidth>
                <DialogTitle>Filter Options</DialogTitle>
                <DialogContent>
                    <Filter onClose={handleFilterClose} />
                </DialogContent>
            </Dialog>
        </LocalizationProvider>
    );
}

// LoginForm.jsx
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, TextField, Container, Typography, Box, Alert } from '@mui/material';
import NavigationBarLogin from './NavigationBarLogin';
import { AuthContext } from './AuthContext'; 

const headers = {
        //'Access-Control-Allow-Origin' : '*'
    }

function LoginForm({}) {
    const { login } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Clear any existing error messages
        try {
            const response = await axios.post('/api/login', { email, password }, {headers: headers});
            if (response.data.success) {
                login(response.data.token); // Assuming 'login' updates the app's state with the received token
            } else {
                // Backend indicates failure but does not throw an error
                setErrorMessage(response.data.error || 'Login failed. Please try again.');
            }
        } catch (error) {
            // If the request itself fails or the backend returns a non-2xx status code
            const errorMessage = error.response?.data?.error || "An error occurred during login.";
            setErrorMessage(errorMessage);
            console.error("Login error", error);
        }
    }
    

return (
        <>
            <NavigationBarLogin/>
            <Container component="main" maxWidth="xs">
                <Box 
                    sx={{
                        marginTop: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    {errorMessage && (
                        <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                            {errorMessage}
                        </Alert>
                    )}
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default LoginForm;

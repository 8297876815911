import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme } from '@emotion/react';
import { ThemeProvider } from '@emotion/react';
import axios from 'axios';
import { Options } from './Options';
import { DateRangeContext } from './DateRangeContext';
import TopicExplorer2 from './TopicExplorer2';

export default function Insights() {
  const theme = useTheme();
  const { dateRange, selectedColumns, selectedFilters, } = useContext(DateRangeContext);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get('/api/dev/data/topics', {
          params: {
            begin: dateRange[0].toISOString(),
            end: dateRange[1].toISOString(),
            selectedFilters: selectedFilters,
            selectedColumns: selectedColumns,
            n: 10
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = response.data;
        const topicObjects = data["Topic Objects"];

        if (!topicObjects) {
          const fallbackTopics = [];
          console.log(fallbackTopics);
          setTopics(fallbackTopics);
        } else {
          setTopics(topicObjects);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchTopics();
  }, [dateRange, selectedFilters, selectedColumns]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
      <Grid container sx={{ mb: 4, mt: 4 }} alignItems="center">
        <Grid item xs>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: "5px" }}>
            Insights
          </Typography>
        </Grid>
        <Grid item>
          <Options />
        </Grid>
      </Grid>

        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
              {topics.length === 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                  <Typography variant="h4" color="textSecondary">
                    No data available
                  </Typography>
                </div>
              ) : (
                <TopicExplorer2 topics={topics} />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

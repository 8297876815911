import React, { useEffect, useState } from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@emotion/react';
import { orange, blue, purple, grey, blueGrey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import { Button, IconButton, Typography, Box, FormControl, TextField, Paper, Dialog, DialogActions, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import AlertDialog from '../AlertDialog';

function SeedTopic({ title, description, setTopicTitle, setTopicDescription, index }) {
    return (
        <Box display="flex" justifyContent="flex-end">
            <Grid container m={1}>
                <Grid item xs={12} mb={1}>
                    <FormControl sx={{ width: "100%" }}>
                        <TextField
                            id="topic_title"
                            placeholder="Topic Title"
                            value={title}
                            variant="standard"
                            onChange={(e) => setTopicTitle(index, e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl sx={{ width: "100%" }}>
                        <TextField
                            id="topic_description"
                            placeholder="Topic Description"
                            value={description}
                            variant="outlined"
                            multiline
                            onChange={(e) => setTopicDescription(index, e.target.value)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
}

function ProjectCreator({ open, onClose, addProject, updateProject, edit, project, index }) {
    const [projectTitle, setProjectTitle] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [seedTopics, setSeedTopics] = useState([]);

    useEffect(() => {
        if (edit && project) {
            setProjectTitle(project.title);
            setProjectDescription(project.description);
            setSeedTopics(project.topics);
        } else {
            setProjectTitle('');
            setProjectDescription('');
            setSeedTopics([]);
        }
    }, [edit, project]);

    const addSeedTopic = () => setSeedTopics([...seedTopics, { title: '', description: '' }]);
    const removeSeedTopic = (i) => setSeedTopics(seedTopics.filter((_, index) => index !== i));
    const setTopicTitle = (i, title) => setSeedTopics(seedTopics.map((topic, index) => index === i ? { ...topic, title } : topic));
    const setTopicDescription = (i, description) => setSeedTopics(seedTopics.map((topic, index) => index === i ? { ...topic, description } : topic));

    const createProjectAndClose = () => {
        const newProject = { id: index, title: projectTitle, description: projectDescription, topics: seedTopics };
        if (edit) {
            updateProject(index, newProject);
        } else {
            addProject(newProject);
        }
        onClose();
    };

    return (
        <Dialog fullScreen={false} onClose={onClose} open={open} fullWidth maxWidth="md">
            <Grid container p={2}>
                <Grid item xs={12} mb={2}>
                    <Box display="flex" justifyContent="flex-end">
                        <FormControl sx={{ width: "100%" }}>
                            <TextField
                                id="title"
                                variant="standard"
                                placeholder="Project Title"
                                value={projectTitle}
                                inputProps={{ style: { fontSize: 30 } }}
                                onChange={(e) => setProjectTitle(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} mb={2}>
                    <Typography variant="h7" fontWeight="bold" mb={2}>Project Description</Typography>
                    <Box display="flex" justifyContent="flex-end">
                        <FormControl sx={{ width: "100%" }}>
                            <TextField
                                id="description"
                                placeholder="Project Description"
                                value={projectDescription}
                                variant="outlined"
                                multiline
                                onChange={(e) => setProjectDescription(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} mb={2}>
                    <Typography variant="h7" fontWeight="bold" mb={2}>Seed Topics</Typography>
                    {seedTopics.map((topic, i) => (
                        <Paper key={i} sx={{ marginTop: "10px" }}>
                            <SeedTopic
                                index={i}
                                title={topic.title}
                                description={topic.description}
                                setTopicTitle={setTopicTitle}
                                setTopicDescription={setTopicDescription}
                            />
                            <IconButton variant='contained' onClick={() => removeSeedTopic(i)}>
                                <DeleteIcon />
                            </IconButton>
                        </Paper>
                    ))}
                    <Button variant='outlined' onClick={addSeedTopic} sx={{ width: "100%", marginTop: "10px" }} color="secondary">
                        <AddIcon />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions width="100%">
                        <Grid container mt={3}>
                            <Grid item xs={6} pr={1}>
                                <Button onClick={onClose} variant="outlined" color="primary" sx={{ width: "100%" }}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={6} pl={1}>
                                <Button onClick={createProjectAndClose} variant="contained" color="primary" sx={{ width: "100%" }}>
                                    {edit ? "Update" : "Create"}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export function Projects() {
    const theme = useTheme();
    const projectColors = [orange, blue, purple];
    const [editing, setEditing] = useState(false);
    const [projects, setProjects] = useState([]);
    const [activeProject, setActiveProject] = useState(null);
    const [currentProject, setCurrentProject] = useState(null);
    const [creatorOpen, setCreatorOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const navigate = useNavigate();


    const showConfirmation = (project) => {
        setAlertOpen(true);
        setCurrentProject(project);
      };

    const handleAgree = () => {
        deleteProject(currentProject);
        setAlertOpen(false);
      };
    
      const handleDisagree = () => {
        setAlertOpen(false);
      };
    
    

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('/api/dev/projects', { params: { token: localStorage.getItem('token') } });
                setProjects(response.data);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
            try {
                const response = await axios.get('/api/dev/projects/active', { params: { token: localStorage.getItem('token') } });
                setActiveProject(response.data);
            } catch (error) {
                console.error('Error fetching active project:', error);
            }
        };
        fetchProjects();
    }, []);

    const handleCreatorClose = () => {
        setCreatorOpen(false);
        setEditing(false);
    };

    const addProject = async (newProject) => {
        try {
            const response = await axios.post('/api/dev/projects/add', { token: localStorage.getItem('token'), project: newProject });
            setProjects(response.data);
        } catch (error) {
            console.error('Error adding project:', error);
        }
    };

    const handleActivateProject = async (id) => {
        try {
            const response = await axios.post('/api/dev/projects/active', { id: id, token: localStorage.getItem('token') });
            setActiveProject(response.data);
            navigate(0);
        } catch (error) {
            console.error('Error activating project:', error);
        }
    };

    const deleteProject = async (project) => {
        try {
            const response = await axios.post('/api/dev/projects/delete', { id: project.id, token: localStorage.getItem('token') });
            setProjects(response.data);
            setActiveProject('');
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };

    const updateProject = async (i, updatedProject) => {
        try {
            const response = await axios.post('/api/dev/projects/update', { token: localStorage.getItem('token'), project: updatedProject });
            setProjects(response.data);
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    return (
        <React.Fragment>
            {projects.map((project, i) => (
                <ListItemButton
                    key={project.id}
                    onClick={(e) => { e.preventDefault(); handleActivateProject(project.id); }}
                    sx={{
                        backgroundColor: blueGrey[300],
                        opacity: project.id === activeProject ? '100%' : '50%',
                        paddingRight: "5px"
                    }}
                >
                        <Tooltip title={project.title}>
                        <ListItemIcon><ListAltIcon /></ListItemIcon>
                        </Tooltip>
                        <Tooltip title={project.title}>
                            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold' }, noWrap: true }} primary={project.title} />
                        </Tooltip>
                        <IconButton
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditing(true); setCreatorOpen(true); }}
                            disabled={project.id !== activeProject}
                            sx={{ display: project.id !== activeProject ? "none" : null, padding: "5px" }}
                        >
                            <SettingsIcon />
                        </IconButton>
                        <IconButton onClick={(e) => { e.preventDefault();  e.stopPropagation();  showConfirmation(project); }} disabled={project.id !== activeProject} sx={{ display: project.id !== activeProject ? "none" : null, padding: "5px" }}>
                            <DeleteIcon />
                        </IconButton>
                </ListItemButton>
            ))}
            <ListItemButton onClick={() => setCreatorOpen(true)}>
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary="Add Project" />
            </ListItemButton>
            <ProjectCreator
                open={creatorOpen}
                onClose={handleCreatorClose}
                addProject={addProject}
                updateProject={updateProject}
                edit={editing}
                project={projects.find(p => p.id === activeProject)}
                index={activeProject}
            />
        <AlertDialog
        open={alertOpen}
        title="Confirm Action"
        message={`Are you sure you want to delete the current Project?`}
        onAgree={handleAgree}
        onDisagree={handleDisagree}
      />
        </React.Fragment>
    );
}
